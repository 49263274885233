.home {
    .main {
        padding: 0;
    }
    .landing {
        width: 100%;
        position: relative;
        .slides {
            display: none;
            @include breakpoint(medium) {
                display: block;
            }
        }
        .mobile {
            background-color: $almost-black;
            background-image: url(/wp/shared/2016/12/Jon-Rivera-GIRLpress4-974x420.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            height: 450px;
            align-items: center;
            justify-content: center;
            .mobile-landing {
                margin: 0;
                padding: 0;
                list-style-type: none;
                &>li {
                    a {
                        color: $white;
                        background-color: transparentize($almost-black, 0.2);
                        padding: 1em;
                        font-weight: 800;
                        border: 1px solid $white;
                        margin: 1em auto;
                        text-transform: uppercase;
                        display: block;
                        text-align: center;
                        transition: background .2s;
                        &:active,
                        &:hover {
                            background: darken(get-color(primary), 10%);
                        }
                    }
                }
            }
            @include breakpoint(medium) {
                display: none;
            }
        }
        // min-height: 300px;
    }
    .main-slider {
        position: relative;
        width: 100%;
    }
    .slides {
        display: block;
        @include breakpoint(medium) {}
    }
    .masthead {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        // font-size: 12vw;
        // width: 60%;
        @include breakpoint(medium) {
            width: 22%;
        }
        .logo {
            width: 100%;
            position: relative;
            left: -2vw;
            img {
                width: 100%;
            }
        }
    }
    .site-tag {
        font-family: $font-secondary;
        box-shadow: 2px 2px 4px -1px $almost-black;
        text-transform: uppercase;
        font-weight: 900;
        text-align: center;
        color: $white;
        background: $brand-primary;
        line-height: 1.2;
        border: .15em solid;
        padding: .4em .8em;
        margin-top: 1em;
        font-size: 1.35rem;
        font-size: 1.2em;
        text-shadow: 1px 1px 1px $almost-black;
        div {
            white-space: nowrap;
        }
    }
    .buy-tickets {
        width: 100%;
        margin-top: 1em;
        max-width: 300px;
        text-transform: uppercase;
        @include breakpoint(medium) {}
    }
    .mission-statement {
        text-align: center;
        padding-top: 6vw;
        padding-bottom: 6vw;
        .button,
        .button-group {
            margin-bottom: 0;
        }
        a {
            text-transform: uppercase;
        }
        @include breakpoint(medium) {
            font-size: 2vw;
        }
        .important {
            font-weight: 900;
            line-height: 1;
            text-transform: uppercase;
            font-size: 1em;
            margin-bottom: 1em;
            @include breakpoint(medium) {
                .break {
                    display: block;
                    white-space: nowrap;
                }
            }
        }
    }
    .now-playing {
        padding-bottom: 1em;
        background: darken($white, 10%);

        .ribbon .ribbon-image {
            width: 30px;
            padding-right: .5em;
        }
        .show-container {
            margin-bottom: 2em;
 
            .top-title {
                @include breakpoint(medium) {
                    text-align: center;
                }
            }

            .show-info {
                padding-bottom: 2em;
                border-bottom: 2px solid #efdabd
            }
        }
        .logline {
            margin-top: 1em;
        }
        .horizontal-artwork {
            position: relative;
            opacity: 1;
            transition: opacity .2s;
            img {
                width: 100%;
            }
            &:hover {
                opacity: .951;
            }
        }
        .details {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 1em;
            padding-bottom: 1em;
            position: relative;
            background: $white;
            border: 1px solid lightgrey;
            box-shadow: 2px 1px 20px -5px $black;
            .info {
                width: 100%;
                @include breakpoint(480) {
                    width: 60%;
                    flex: 0 0 60%;
                }
            }
            .tickets {
                width: 100%;
                flex: 1 0 100%;
                .button {
                    margin-top: .5em;
                }
                a {
                    display: block;
                    text-transform: uppercase;
                }
                @include breakpoint(480) {
                    flex: 0 1 30%;
                    margin-left: .5em;
                }
                @include breakpoint(medium) {
                    margin-left: 0;
                    flex: 0 1 100%;
                }
                @include breakpoint(900) {
                    margin-left: .5em;
                    flex: 0 1 30%;
                }
            }
            @include breakpoint(medium) {
                margin-left: 1em;
                margin-top: -2em;
            }
        }
        .bottom-row {
            position: relative;
            height: 50px;
            .button {
                font-size: 1.25em;
                position: absolute;
                left: 50%;
                transform: translatex(-50%);
                @include breakpoint(medium) {
                    left: auto;
                    transform: none;
                    right: 20%;
                }
            }
        }
    }
    #googleMap.reveal {
        .reveal-show-info {
            order: 1;
            @include breakpoint(medium) {
                order: 0;
            }
            img {
                width: 100%;
            }
        }
        .show-title {
            color: $white;
        }
        padding: 0;
        background-color: transparentize($black, 0.3);
        img {
            box-shadow: -1px 1px 8px 0px $black;
        }
        .button {
            margin-top: .5em;
        }
        a {
            text-transform: uppercase;
        }
    }
    .artistic-arms {
        background: $black;
        padding: 1em 0;
        a {
            display: inline-block;
            height: 100%;
            width: 100%;
            transition: background .2s;
            &:hover {
                background: darken($brand-primary, 10%);
            }
        }
        .act-theater {
            // height: 15vw;
            .row {
                height: 100%;
                align-items: center;
                align-content: center;
            }
            .title {
                font-size: 1rem;
                position: relative;
                text-align: center;
                align-content: center;
                line-height: 1;
                display: flex;
                align-items: center;
                width: 100%;
                height: 80%;
                flex-wrap: wrap;
                border-right: 4px solid $white;
                @include breakpoint(medium) {
                    border-bottom: none;
                }
            }
            .act-description {
                p {
                    color: $white;
                    line-height: 1.3;
                }
            }
            .parent {
                // display: flex;
                // justify-content: space-between;
                font-weight: 900;
                font-size: 1em;
                display: block;
                line-height: .91;
                letter-spacing: -.11em;
                text-shadow: 1px 1px 4px darken($brand-primary, 35%);
            }
            .child {
                width: 100%;
                font-weight: 300;
                text-align: center;
                line-height: .91;
            }
        }
    }
    .social-content {
        padding-bottom: 1em;
        .orange-bg .content-wrap {
            background-clip: content-box;
            background-color: get-color(primary);
            color: $white;
            a {
                color: $white;
            }
        }
        .white-bg .content-wrap {
            background-clip: content-box;
            background-color: $white;
            color: black;
        }
        .text-only {
            .img-container {
                display: none;
            }
            &.small .content-wrap {
                opacity: 1;
            }
        }
        .right {
            order: 3;
        }
        .social-highlight {
            min-height: 250px;
            margin-top: 1em;
            position: relative;
        }
        .img-container {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-clip: content-box;
            // height: 100%;
            min-height: 250px;
        }
        .medium {
            .content-wrap {}
            .img-container {
                position: absolute;
                left: 0;
                width: 100%;
                height: 100%;
                top: 0;
                z-index: -1;
            }
        }
        .full {
            .img-container {
                width: 33.33333%;
                flex: 0 0 33.33333333%;
            }
        }
        .full,
        .medium {
            display: flex;
            align-content: center;
            .img-container {
                display: none;
                @include breakpoint(medium) {
                    display: block;
                }
            }
            .content {
                position: relative;
                text-align: center;
                display: inline-block;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
            }
            .social-bar,
            p,
            .link,
            .source-citation {
                flex: 1 1 100%;
            }
            .social-bar {
                background: $brand-primary;
                padding: .5em;
                text-align: right;
                font-family: $font-secondary;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                flex: 1 1 100%;
                ;
                align-self: flex-start;
            }
            .source {
                font-size: 2em;
            }
            .citation {
                text-transform: uppercase;
                font-size: .9em;
            }
            .main-content {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;
                align-self: flex-start;
                width: 100%;
            }
            p {
                padding: 0 10%;
                line-height: 1.1;
                font-size: 1.3em;
                font-family: $font-secondary;
                font-style: italic;
                font-weight: 600;
            }
        }
        .small {
            position: relative;
            float: left;
            &:hover {
                .content-wrap {
                    opacity: 1;
                }
            }
            img {
                // position: relative;
                // width: 100%;
            }
            .content-wrap {
                opacity: 0;
                transition: opacity .2s;
                @include absolute;
                // background-color: transparentize($white, 0.2);
                background-clip: content-box;
                z-index: 0;
            }
            .content {
                position: relative;
                width: 100%;
                padding: 1em;
                height: 100%;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                &>* {
                    text-align: center;
                    flex: 0 100%;
                }
            }
        }
    }
}

.archive {
    .page-header {
        // margin: auto;
    }
    .subtitle {
        width: 100%;
        margin: auto;
        @include breakpoint(medium) {
            width: 60%;
        }
    }
    .past-shows {
        margin-bottom: 1em;
        padding-bottom: 1em;
        // border-bottom: 1px solid get-color(primary);
        .year {
            font-size: 1em;
            border-bottom: 2px solid transparentize(darken(get-color(primary), 15%), 0.6);
        }
    }
    .past-shows-item {
        display: flex;
        justify-content: center;
        margin-bottom: 1em;
        img {
            width: 100%;
            border: 1px solid #ccc;
            transition: all .2s;
            transition-property: box-shadow;
            &:hover {
                box-shadow: 2px 2px 4px -2px $almost-black;
            }
        }
        header {
            font-family: $font-secondary;
            font-weight: 900;
            .dates {
                font-family: $font-primary;
                font-weight: 300;
                font-size: .9em;
                text-transform: uppercase;
                text-align: right;
            }
        }
    }
}

.about-us {
    .section-title {
        padding-top: 2em;
        margin-top: 2em;
        margin-bottom: 2em;
        // border-top: 2px solid;
        border-bottom: 1px solid #ccc;
        font-size: .9em;
        text-transform: uppercase;
        font-weight: 900;
    }
    .sub-section-title {
        display: inline-block;
        border-bottom: 1px solid #ccc;
        width: 90%;
        text-transform: uppercase;
        font-weight: 600;
        font-size: .8em;
    }
    .donationCTA {
        width: 100%;
    }
    .name {
        // text-transform: uppercase;
        // font-family: $font-secondary
    }
    #contact {
        background: $almost-black;
        color: $white;
        address>span {
            font-size: 1.25em;
            margin-bottom: 1em;
        }
    }
}

.new-pages {
    position: relative;
    padding-top: 3vw;
    padding-bottom: 6vw;
    &:before {
        content: "";
        @include absolute;
        width: 90%;
        left: 50%;
        height: 70%;
        top: 50%;
        max-height: 500px;
        transform: translate(-50%, -50%);
        background-image: url(../images/pen-bg.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .row {
        max-width: 100%;
        position: relative;
        @include breakpoint(medium) {
            max-width: 85%;
        }
        @include breakpoint(medium) {
            max-width: 950px;
        }
    }
    .new-pages-title {
        font-weight: 900;
        text-transform: uppercase;
        font-size: 11vw;
        line-height: 1;
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-shadow: 3px 3px 2px $black;
        display: table;
        @include breakpoint(medium) {
            font-size: 8vw;
        }
        .parent {
            position: relative;
            .child {
                font-size: .25em;
                display: block;
                line-height: .71;
                text-align: right;
            }
        }
        .color1 {
            color: $white;
        }
        .color-primary {
            color: $brand-primary;
        }
    }
    .subtitle {
        width: 90%;
        text-align: center;
        font-size: 1em;
        text-transform: uppercase;
        margin-top: -1em;
        @include breakpoint(medium) {
            width: 48vw;
        }
    }
    .reading-event {
        margin-bottom: 1em;
        position: relative;
        &:last-of-type {
            margin-bottom: 2em;
        }
        // &:nth-of-type(odd){
        // .reading-box-wrap{
        // order: 1;
        // }
        // }
    }
    .reading-box {
        border: 0.51em solid rgba(255, 255, 255, 0.5);
        padding: 2em 2em;
        background-color: transparentize($black, 0.5);
        img {
            border: 1px solid darkgrey;
            width: 90px;
            float: left;
            margin-right: 1em;
        }
        p {
            font-weight: 100;
            margin-bottom: 0;
        }
        .reading-header {
            border-bottom: 2px solid transparentize($white, 0.6);
            padding-bottom: .5em;
        }
        .reading-title {
            font-weight: 900;
            font-family: $font-secondary;
            text-transform: uppercase;
            margin-bottom: 0;
            line-height: 1;
        }
        .credit-title {
            padding-right: .5em;
            font-size: .8em;
        }
        [class^="reading-credit-"] {
            margin-bottom: 0;
            display: block;
            font-weight: 100;
        }
    }
    aside {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .reading-details-wrap {
        background-color: transparentize($black, 0.5);
        background-clip: content-box;
        justify-content: center;
        align-self: flex-start;
    }
    .performance-details {
        padding: 1em;
        .title,
        .author,
        .director,
        .actors {
            display: none;
        }
        .time {
            font-family: $font-secondary;
            font-weight: 800;
            font-size: 2em;
            color: $color-primary;
        }
        .location {
            margin: .51em auto;
        }
        .venue {
            text-transform: uppercase;
            font-size: .8em;
        }
        .street,
        .city {
            font-size: .75em;
        }
    }
}

.page.new-pages-lab {
    .wrap.container {
        background: $almost-black;
        color: $white;
        .page-header {
            color: $black;
        }
    }
    .callout {
        color: $white;
        background-color: $black;
        max-width: 500px;
        margin: auto;
        border: 2px solid lighten($almost-black, 20%);
        p {
            font-size: .9em;
        }
    }
    .after-content .new-pages {
        .new-pages-title {
            display: none;
        }
        .subtitle {
            display: none;
        }
        footer p {
            display: none;
        }
    }
}

.page.news main article {
    background-color: $white;
    padding: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
    position: relative;
    border: 2px solid #ccc;
    .published-date {
        color: $brand-primary;
    }
}