.single-theater_show {
	
	.headshot-gallery {
		.images-item {
			margin-bottom: 1em;
			img {border: 1px solid #ccc;}
		}
	}
	.post-header-bg {
		width: 100%;
		position: relative;
	}
	.show-meta {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		position: relative;
		width: 100%;
		padding: 2em 1em 0;
		text-align: center;
		@include breakpoint(medium){
			padding: 3em 2em 0em;
		}
		.ticketing {
			position: fixed;
			bottom: 0;
			width: 100%;
			left: 0%;
			@include breakpoint(medium){
				position: relative;
				left: 0;
				width: 100%;
			}
			.button {
				margin-bottom: 0;
			&.disabled {
				display: none;
			}
			
			@include breakpoint(medium){
				&.button.disabled {
					display: block;
				}
			}
		}
	}
		@include breakpoint(880){
			text-align:left;
			flex-wrap: nowrap;
		}
		.main-title{
			flex: 3 1 66%;
			width: 100%;
			@include breakpoint(medium){
				.show-for-small-only {
					display:block!important;
				}
			}
			@include breakpoint(960){
				flex: 0 1 auto;
				width: 63%;
				.show-for-small-only {
					display:none!important;
				}
			}
		}
		.title-sidebar{
			flex: 1 1 100%;
			text-align: center;
			margin-top: 1em;
			font-size: .9em;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 2em;
			@include breakpoint(medium){
			margin-top: 1em;
			.show-for-medium {
				display: none;
			}
			}
			@include breakpoint(960){
				margin-top: 0;
				flex: 0 3 30%;
				.show-for-medium {
					display: block;
					width: 100%;
					margin-bottom: .5em;
				}
				
			}
		}
		.show-title {
			font-family: $font-secondary;
			letter-spacing: -2px;
			text-transform: uppercase;
			font-weight: 900;
			line-height: 1;
			width: 100%;
		}
		@include breakpoint(medium){
			margin-bottom: 55px;
			z-index: 1;
			position: relative;
			margin-top: -85px;
			margin-top: -5vw;
			background-color: darken($white, 10%);
			// box-shadow: 0px 2px 9px -2px $black;
		}
	}
	.dates {
		line-height: 1;
		.opening-date,
		.closing-date,
		.separator {
			display: inline-block;
		}
		font-size: .9em;

		display: block;
		.day {
			text-transform: uppercase;
			font-size: .7em;
			display:block;
		}
	}

.title-credits {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	@include breakpoint(medium){
		display: block;
	}
	.credit-item {
		display: inline-block;
		margin: auto;
		@include breakpoint(medium){
			display: block;
		}
	}
}
// peopl's credits
.actors-wrap {
	@include clearfix;
	display: flex;
	flex-wrap: wrap;
	padding: 1em 1em 0;
	.actors-item {
		flex: 1 1 50%;
		padding: .5em;
		@include breakpoint(520){
			flex: 1 1 25%; 
		}
		img {
			transition: box-shadow .2s;
		}
		a {display:block; color: $black;}
		a:hover img{
			box-shadow: 0px 0px 15px -2px $black;
		}
	}
	.credit-headshot {width: 100%; border: 1px solid #ccc;}
	.credit-value {line-height: 1;display:inline-block; margin-top: .5em; }
}
 .credits {
 	// font-size: .9em;
 	line-height: 1;

 	.credit-title {
 		text-transform: lowercase;
 	}
 	.credit-value {
 		text-transform: uppercase;
 	}
 }
	.credit-title {
	    text-transform: uppercase;
	    font-size: .75em;
	    font-family: $font-primary;
	    font-weight: 400;
	}
	.credit-value {
		// text-transform: capitalize;
		font-family: $font-secondary;
	}
.venue-address {
	font-size: .8em;
}
.sidebar {
	margin-top: 55px;
	
}
.show-specifics {
	.facebook-event {
		font-weight: 800;
		text-transform: uppercase;
	}
	.fa {
		font-size: 1.5em;
	}
	
	.sidebar-title {
		font-family: $font-secondary;
		font-size: .8em;
		font-weight: 600;
		text-transform: uppercase;
		
	}
}
.ovation {
	float: none;
	width: 100%;
	border: none;
	@include breakpoint(460){
		width: 25%;
		    max-width: 190px;
		    min-width: 170px;
		    float: left;
		    margin-right: 1em;
		    border: 1px solid;
		    padding: .3em;
		}
}
.log-line {
		font-size: 1.2em;
}
.primary-review {
	blockquote {
		line-height: 1.2;
		font-size: 1.5em;
		font-weight: 900;
		font-family: $font-secondary;
		color: $black;
		cite {
			font-family: $font-primary;
		}
}
}
}