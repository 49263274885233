.button , .button.primary {
	color: $white;
	box-shadow: 1px 1px 4px -1px $black;
	transition: box-shadow .2s, background .2s, color .2s;
	&:hover, &:focus {
		color: $white;
		box-shadow: 1px 1px 1px -1px $black;
		background: adjust-hue($button-background, -5%);
	}
	&:active {
		background: adjust-hue($button-background, 5%);
	}
}

.button-group.large .button {
	@include breakpoint(small down){
	font-size: 1em;
}
}
.button-link {
	cursor: pointer;
	color: $brand-primary;
}

.button.button-white, .button.button-white:visited{
	transition: all .2s;
	transition-property: color, border-color;
	&:hover {
	color: $white;
	border-color:$white;
	}
	&:active {
		background: adjust-hue($button-background, 5%);
	}
}