// Grid system
.main {
	@include flex-grid-column(12);
	@include breakpoint(medium){
		.sidebar-primary & {
		  @include flex-grid-column(8);
		}
	}
}
.sidebar {
	@include flex-grid-column(12);
	@include breakpoint(medium){
		@include flex-grid-column(4);
	}
}
