// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         get-color(primary);
$color-primary:          $brand-primary;
$almost-black: 			#252525;

$offcanvas-background: darken($brand-primary, 10%);


