.site-footer {
	border-top: .2em solid darken($brand-primary, 5%);
	padding-top: 2em;
	background: $brand-primary;
	color: $white;
	position: relative;
	z-index: 2;
	.row {
	}
	.footer-menu  {
		margin-top: 3vw;
		&.menu {
			flex-wrap: wrap;
		}
		a {
			display:inline-block;
			width: 100%;
			color: $white;
			padding: .5em;
			transition: background .2s, color .2s;
			&:hover {
				background: $white;
				color: $brand-primary;
			}
		}
		.social a {
			width: auto;
			border-radius: 8px;
			text-align: center;
			.fa {
				font-size: 1.5em;
				margin-right: 0;
			}
		}
	}
	.footer-watermark {
		margin-top: 5vw;
		opacity:.25;
		position: relative;
		// font-size: 7vw;
		text-align: center;
		font-family: $font-secondary;
		font-weight: 900;
		text-transform: uppercase;
		line-height: .74;
		font-size: 6vw;
		overflow:hidden;
		span {
			display: block;
			&:first-of-type{
				font-size: 1em;
			}
			&:nth-of-type(2){
				line-height: .74;
				font-size: 3.5em;
			}
		}
	}
	.signUpFormModal.loner {
		text-transform: uppercase;
		color: $white;
		width: 100%;
		display: block;
		text-align: center;
		font-weight: 800;
		font-size: 1.2em;
		background: transparentize($white, .7);
		font-family: $font-secondary;
		padding: 1em;
		transition-property: background, color, border;
		transition: all .2s;
		// border: 2px solid darken(get-color(primary), 15%);
		&:hover {
			background: $white;
			color: get-color(primary);
			border-color: darken(get-color(primary), 15%);
		}
	}
	.footer_link_block {
		display: flex;
		    margin-top: 10px;
		    flex-wrap: wrap;
		    justify-content: center;
	}
	.footer_link_desc {
		flex: 11 40%;
		    padding: 0.5rem;
		    text-align: center;
		    min-width: 250px;
	}
	.footer_btn {
		text-transform: uppercase;
		color: $white;
		display: inline-block;
		text-align: center;
		font-weight: 800;
		background: transparentize($white, .7);
		font-family: $font-secondary;
		padding: 1em;
		transition-property: background, color, border;
		transition: all .2s;
		flex: 1 1 21%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1rem;
		min-width: 230px;
		    max-width: 299px;
		&:hover {
			background: $white;
			color: get-color(primary);
			border-color: darken(get-color(primary), 15%);
		}
	}
}