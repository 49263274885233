body.no-js {
	opacity:0;
}
html {
	position: relative;
}

body {
	opacity: 1;
	transition: opacity .8s;
	// background: darken(get_color(primary), 35%);
	position: relative;
	z-index: 1;
	&:after {
		content: '';
		position: fixed;
		top:0;
		width: 100%;
		height: 100vh;
		// background-image: url(../images/dark_spot_background.jpg);
		// background-image: url(../images/leaf_background.jpg);

		background-size: cover;
		background-position: center;
		z-index: 0;
		// background-attachment: fixed;

	}
}

img { width: 100%;}
.off-canvas-content {
	overflow:hidden;
	background: transparentize(darken($brand-primary,5%),.6);
	// border-left: 5px solid darken(get_color(primary),60%);
	position: relative;
	z-index: 1;
}
.wrap.container{
	background: darken($white, 10%);
	position: relative;
	z-index: 1;
	// overflow:hidden;
}
.amp {
	font-size: .9em;
	padding: 0 .3em;
}
.nowrap {
	white-space: nowrap;
}

.menu-icon-wrap {
	display: inline-block;
	position: absolute;
	z-index: 1;
	right: 0;
	padding-right: 1em;
}

.bg-dark:not(.menu-icon) {
	background: $almost-black;
	color: $white;
}
.bg-light:not(.menu-icon) {
	background: $white;
	color: $almost-black;
}
.bg-special {
	background: $primary-color;
	color: $white;
}

.top-title {
	margin: .6em 0;
	text-transform: uppercase;
	font-size: .9rem;
	font-family: $font-secondary;
	font-weight: 900;
	
	// text-shadow: 1px 1px 2px $black;
}

.ribbon {
	position: absolute;
	right: 1em;
	top: -1em;
	font-size: .7em;
	text-transform: uppercase;
	color: $black;
	background: $brand-primary;
	padding: .3em;
	display: inline-block;
	
	@include breakpoint(medium){
		background: initial;
		@include ribbon;
	}
}
.row .row.nested {
  max-width: 75rem;
  margin-right:0;
  margin-left:0;
  width: 100%;

}

button.secondary {
	 color: $secondary-color!important;
}
.text-uppercase {
	text-transform: uppercase;
}
.content.row {
	.about-us &,
	.archive & {
	max-width:60em;
	}
}
.show-title {
	text-transform: uppercase;
	font-weight: 900;
	font-size: 1.2em;
	font-family: $font-secondary;
}
.page-header {
	position: relative;
	    margin-top: -35px;
	    margin-bottom: 35px;
	    z-index: 10;
	    background: darken($white, 10%);
	    min-height: 55px;
	    padding: 1vw 2vw;
	    // box-shadow: 2px 2px 2px -2px black;
	    display: inline-block;
	    font-size: 2em;
	    font-weight: 800;
	    @include breakpoint(large){
	    font-size: 3vw;}
	    h1 {
	    		text-transform: uppercase;
	    		font-size: 1.1em;
	    		font-weight: 900;
	    		margin-bottom: 0
	    	}
}

.donationCTA {
	color: $white;
	position: relative;
	// background-image: url(../images/slide-bloodletting.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;

	&:before {
		content: '';
		@include absolute;
		background: transparentize(darken($brand-primary,20%), .3);
		transition: background-color .2s;
	}
	&:hover{
		&:before {
		background-color: transparentize(darken($brand-primary,50%), .3);
	}
		.important {
			border: 2px solid transparentize($white, .5);


		}
}

		a{
		color: $white;
	}
	.row {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		min-height: 15vw;
		padding: 2em 0;

	}
	.important {
		margin-bottom: 0;
		border: 2px solid transparent;
		padding: 1em 0;
		font-weight: 900; 
		text-align: center;
		line-height: 1; 
		text-transform: uppercase;
		font-size: 5vw;
		transition: border .2s;
		@include breakpoint(medium){
			font-size: 1.5em;
		}
	}
	.button {text-transform: uppercase;}

}

.mailingList-CTA {	
	padding: 1em;
	background: $almost-black;
	text-align: center;
	color: $white;
	h3 {
		margin: 0;
	}

}

.block-link {
	display: block;
}

.contained-full-width {
	// margin: 0 -50vw;
	// padding: 0 50vw;
}

html.is-reveal-open {
	@include breakpoint(medium){
	padding-right: 16px;}
}

// to reset content editor bg in admin
body.mceContentBody {
  background-color:#FFFFFF !important;
  background-image: none;
  text-align: left;
}

.sidebar {
	margin-top: 6vw;
	ul {
	   text-align: RIGHT;
	   background: $almost-black;
	   padding: 2em;
	   li {}
	   a:not(.button) {
	   	display: block;
	   	width: 100%;
	   	height: 100%;
	   	text-transform: uppercase;
	    font-size: .85em;
	    padding: .5em 1em;
	    transition: background .1s;
	    &:hover {
	    	background: $white;
	    }	   		 
	   }
	}
}

.border {
	box-shadow: 0px 0 1px 0px $black;
	border: 3px solid transparent;
}

cite {
	display:inline-block;
}

