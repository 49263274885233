.mobile-header {
    display: flex;
    align-items: center;
    background: $almost-black;
    border-bottom: 1px solid $black;

    @include breakpoint(medium) {
        display: none;
    }


    .mobile-logo {
        width: 160px;

        // margin: auto;
        padding: .51em;
    }
}

header {
    a.brand {
        display: inline-block;
        width: 90px;

        // height: 100px;
        &:hover {
            background: none;
        }

        .logo {
            display: inline-block;
            height: 100%;
            width: 100%;
            background-image: url("../images/logo-secondary.png");
            background-size: contain;
            background-repeat: no-repeat;
            overflow: hidden;
            text-indent: 100%;
            white-space: nowrap;
        }
    }

    .menu {
        @include menu-direction(vertical);

        @include breakpoint(medium) {
            @include menu-direction(horizontal);
        }
    }
}

.off-canvas {
    #closeOffCanvas {
        display: inline-block;
        position: absolute;
        right: 10px;
        text-align: right;
        width: auto;


        @include breakpoint(medium) {
            display: none;
        }
        .fa {
            color: $white;
            padding: .51em;
            margin: .5em;
            font-size: 1.2em;
            cursor: pointer;
            transition: all .2s;
            transition-property: background, color;
            &:hover {
                background: $white;
                color: $brand-primary;
            }
        }
    }

    @include breakpoint(medium) {
        // position:relative;
        position: absolute;

        .home & {
            position: absolute;
        }
    }
}

@include breakpoint(medium) {
    .top-bar, .top-bar ul {
        background-color: transparent;

        a {
            text-shadow: 0 0 8px $black;
            color: $white;
            text-transform: uppercase;
            transition: background .2s, color .2s;

            &:hover, li.active & {
                text-shadow: none;
                background: $white;
                color: $brand-primary;
            }
        }

        .is-dropdown-submenu {
            background: $brand-primary;
        }
    }

    .off-canvas.reveal-for-medium {
        z-index: 2;
        background: transparent;
        overflow: visible;
        width: 100%;
        height: auto;

        // max-width: 1280px;
        // margin: auto;
        // transform: translatex(-50%);
        // left: 50%;
   
    }

    .position-left.reveal-for-medium ~ .off-canvas-content {
        margin-left: initial;
    }
}


    @include breakpoint(small only) {
        header a.brand {
            width: 30%;
        }
        #closeOffCanvas {
            outline: none;
        }
    .top-bar {
        text-align: center;
    }
    #menu-main-menu {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 2em;
        li {
            display: flex;
            justify-content: center;
            text-align: center;
            a {
                font-size: 1.5em;
                text-transform: uppercase;
            }
        }
        .is-dropdown-submenu {
            background: get-color(primary);
            width: auto;
            right: initial;
            left: 50%;
            transform: translatex(-50%);
            a {
                font-size: .95em;
                text-transform: uppercase;
                padding: 1em;
                color: $black;
                font-weight: 600;
            }

        }
    }
}