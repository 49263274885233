.frm_style_formidable-style.with_frm_style fieldset{
	padding: 1em;
	padding-bottom :0;
}

.contact-form {
	margin-bottom: 1em;
	fieldset {
		background: lighten($almost-black, 35%) !important;
		color: $white !important;
		border: lighten($almost-black, 5%)!important;
	}
}

#contact {
	fieldset {
		padding: 1em;
	}
}