$font-primary: 'Open Sans', sans-serif;
$font-secondary: 'Roboto', sans-serif;
$header-font-family: $font-secondary;
$body-font-family: $font-primary;

$dropdownmenu-arrows: false;

$foundation-palette: (
  primary: #ff9b0b,
  secondary: #767676,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
  white: #fefefe,
);

$body-background: darken(white, 10%);
$buttongroup-spacing: 2vw;
$topbar-background: transparent;
// $body-background: #fff;
// $offcanvas-shadow: none;
$offcanvas-background:#252525;
$offcanvas-exit-background: transparentize(desaturate(darken(orange, 35%), 50%),.2);
$offcanvas-transition-timing: ease-in-out;
$offcanvas-transition-length: .2s;
$offcanvas-size: 100%;
// $buttongroup-margin: 2vw;
// 
// slick slider
$slick-font-path: "../fonts/";
$slick-loader-path: "../images/";

