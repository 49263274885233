@mixin absolute($top: 0, $right:0, $bottom: 0, $left: 0 ){
	position: absolute;
	top: $top;
	right: $right;
	bottom:$bottom;
	left: $left;
}

@mixin ribbon($color1: darken($brand-primary,10%), $color2: $brand-primary){
	  position: absolute;
	  left: -6px; top: -6px;
	  z-index: 1;
	  overflow: hidden;
	  width: 195px; height: 195px;
	  text-align: center;
	& span {
		display:inline-block;
		padding: 1em;
	  font-size: 10px;
	  font-weight: 300;
	  font-family: $font-secondary;
	  color: $black;
	  text-transform: uppercase;
	  text-align: center;
	  line-height: 17px;
	  transform: rotate(-45deg);
	  -webkit-transform: rotate(-45deg);
	  width: 195px;
	  display: block;
	  background: $color1;
	  background: linear-gradient($color2 0%, $color1 100%);
	  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	  position: absolute;
	  top: 40px; left: -39px;
	}
	& span::before {
	  content: "";
	  position: absolute; left: 0px; top: 100%;
	  z-index: -1;
	  border-left: 3px solid $color1;
	  border-right: 3px solid transparent;
	  border-bottom: 3px solid transparent;
	  border-top: 3px solid $color1;
	}
	& span::after {
	  content: "";
	  position: absolute; right: 0px; top: 100%;
	  z-index: -1;
	  border-left: 3px solid transparent;
	  border-right: 3px solid $color1;
	  border-bottom: 3px solid transparent;
	  border-top: 3px solid $color1;
	}
}