$arrowSize: 30px;
.slick-next:before, 
.slick-prev:before {
	font-size: $arrowSize;
	opacity: .5;
	transition: opacity .2s;
	color: $brand-primary;
	text-shadow: 1px 1px 2px black;
}
.slick-next {
	right: 25px;
	height: $arrowSize;
	width: $arrowSize;
	z-index: 1;
}
.slick-prev {
	height: $arrowSize;
	width: $arrowSize;
	left: 25px;
	z-index: 1;
}

.slide {
	position: relative;
}
.slide.slick-slide {
	img {
	width: 100%;
	}
}

